import { Link, useLocation } from "react-router-dom";
import "./Header.css";

function Header({ isMenuOpen, setIsMenuOpen }) {
    const location = useLocation();
    
    const urlParams = new URLSearchParams(window.location.search);
    const isInApp = urlParams.get("source") === "ClickNLearnApp" ||
                    navigator.userAgent.includes("ClickNLearnApp") ||
                    window.isClickNLearnApp;

    // Add check for Download, Feedback, and Help pages
    const isSpecialPage = location.pathname === '/download' || location.pathname === '/feedback' || location.pathname === '/help';
    
    // If in app and on Download/Feedback/Help page, don't render the header
    if (isInApp && isSpecialPage) {
        return null;
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header>
            <div className="header-content">
                <img
                    src={`${process.env.PUBLIC_URL}/logo.png`}
                    alt="Click-n-Learn Logo"
                    className="header-logo"
                />
                {!isInApp && location.pathname !== '/get-click-n-learn' && (
                    <Link to="/get-click-n-learn" className="get-click-n-learn-button" onClick={() => setIsMenuOpen(false)}>
                        Get Click-n-Learn
                    </Link>
                )}
            </div>
            <nav>
                <button className="hamburger" onClick={toggleMenu}>
                    <i className={isMenuOpen ? "fas fa-times" : "fas fa-bars"}></i>
                </button>
                <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
                    <Link to="/" onClick={toggleMenu}>Home</Link>
                    <Link to="/download" onClick={toggleMenu}>Download</Link>
                    <Link to="/help" onClick={toggleMenu}>Help</Link>
                    <Link to="/feedback" onClick={toggleMenu}>Feedback</Link>
                </div>
            </nav>
        </header>
    );
}

export default Header;